import React, {useEffect, useState} from "react"
import GoogleMapReact from "google-map-react"
import cn from "classnames"
import { fitBounds } from "google-map-react/utils"
import mapStyles from "../utils/map-styles"


const Marker = ({count, setActivePlace, id, activePlace, refs}) => {
  const triggerScroll = (id) => {
    refs[id].current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div
      onMouseEnter={() => setActivePlace(id)}
      onMouseLeave={() => setActivePlace("")}
      onClick={() => triggerScroll(id)}
      className={cn({active: id === activePlace}, "map-marker")}
    >
      {count}
    </div>
  )
}

const Map = ({places, activePlace, setActivePlace, triggerScroll, refs}) => {
  const locations = places.map(place => place.coordinates);
  const [size, setSize] = useState({width: 240, height: 200});

  useEffect(() => {
    setSize(
      {
        width: document.querySelector("#map").clientWidth, 
        height: document.querySelector("#map").clientHeight
      }
    )
  }, []);
  
  const bounds = locations.reduce((result, current) => {
    return {
      nw: {
        lat: Math.min(current.lat, result.nw.lat), 
        lng: Math.min(current.lon, result.nw.lng)
      },
      se: {
        lat: Math.max(current.lat, result.se.lat), 
        lng: Math.max(current.lon, result.se.lng)
      }
    }
  }, {nw: {lat: Infinity, lng: Infinity}, se: {lat: -Infinity, lng: -Infinity}});
  
  const {center, zoom} = fitBounds(bounds, size);

  return (
    <div id="map" className="map-wrapper bg-light">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAP_API_KEY }}
        center={center}
        zoom={zoom}
        options={{styles: mapStyles}}
      >
        {places.map((place, i) =>(
          <Marker
            key={place.id}
            id={place.id}
            lat={place.coordinates.lat}
            lng={place.coordinates.lon}
            count={i + 1}
            setActivePlace={setActivePlace}
            activePlace={activePlace}
            triggerScroll={triggerScroll}
            refs={refs}
          />
        ))}
      </GoogleMapReact>
    </div>
  )
}

export default Map;