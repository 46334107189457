import React, {useState} from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faLink, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import cn from "classnames"
import Img from "gatsby-image"
import Map from "../components/map"
import Slider from "../components/slider"

function collectionTemplate({data}) {
  const collection = data.contentfulCollection;
  const site = data.site.siteMetadata;
  const places = collection.places;

  const refs = places.reduce((acc, place) => {
    acc[place.id] = React.useRef();
    return acc;
  }, {});

  const [activePlace, setActivePlace] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (
    <Layout
      title={`${collection.title} | ${site.title}`}
      description={collection.description && collection.description.description || site.description}
      url={site.url}
    >
      <section className="container-fluid">
        <div className="row">
          <div className="flex-grow-1 order-md-last">
            <Map places={places} activePlace={activePlace} setActivePlace={setActivePlace} refs={refs} />
          </div>
          <div className="list-wrapper col-md-6 col-lg-4 overflow-auto pt-4">
            {collection.categories && (
              <div className="py-2">
                {collection.categories.map((category, i) => (
                  <span key={i} className="badge badge-light mr-1">
                    {category.tagName}
                  </span>
                ))}
              </div>
            )}
            <h1 className="font-weight-light">{collection.title}</h1>
            <div className="small text-muted mb-2">by {collection.author.name}</div>
            {collection.description &&
              <div>
                <div
                  className={cn({open: isOpen}, "place-description")}
                  dangerouslySetInnerHTML={{
                    __html: collection.description.childMarkdownRemark.html
                  }}
                />
                <div className="place-description-button-wrapper mb-4 text-center position-relative">
                  <button className="btn btn-sm btn-light rounded-pill" onClick={toggle}>
                    {isOpen ? "Show less" : "Read more"}
                  </button>
                </div>
              </div>
            }
            {places.map((place, i) => (
              <div
                key={place.id}
                ref={refs[place.id]}
                onMouseEnter={() => setActivePlace(place.id)}
                onMouseLeave={() => setActivePlace("")}
                className={cn({active: place.id === activePlace}, "place-card", "shadow-sm", "rounded mb-4")}
              >
                <div className="p-3">
                  <h5 className="d-flex align-items-center mb-0">
                    <span className="count-badge mr-2">{i+1}</span>
                    {place.name}
                  </h5>
                </div>
                {place.images &&
                  (place.images.length > 1 ?
                    <Slider images={place.images} />
                  :
                    <Img fluid={place.images[0].fluid} />
                  )
                }
                <div className="p-3">
                  {place.description &&
                    <div
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html: place.description.childMarkdownRemark.html
                      }}
                    />
                  }
                  <ul className="list-unstyled small mb-0">
                    {place.address && (
                      <li className="text-muted mb-1">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                        <span>{place.address}</span>
                      </li>
                    )}
                    {place.openingTimes && (
                      <li className="text-muted mb-1">
                        <FontAwesomeIcon icon={faClock} className="mr-2" />
                        <span>{place.openingTimes}</span>
                      </li>
                    )}
                    {place.url && (
                      <li className="text-muted">
                        <FontAwesomeIcon icon={faLink} className="mr-2" />
                        <a href={place.url} target="_blank" rel="noopener noreferrer">{place.url.replace(/(^\w+:|^)\/\//, '')}</a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default collectionTemplate;

export const collectionQuery = graphql`
  query collectionBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
    contentfulCollection(slug: {eq: $slug}) {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      author {
        name
      }
      categories {
        tagName
      }
      places {
        id
        name
        address
        openingTimes
        url
        description {
          childMarkdownRemark {
            html
          }
        }
        coordinates {
          lat
          lon
        }
        images {
          id
          fluid(maxWidth: 800, quality: 50) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
      }
    }
  }
`
